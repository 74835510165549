import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import { Field, ErrorMessage } from 'formik';
import { format, subMinutes } from 'date-fns';
import * as Styled from './../styles';
import * as Notes from './styles';
import { graphql, useStaticQuery } from 'gatsby';
import { deliveryTimeOptions } from '../../../state/utils';

const DeliveryAccept = ({ locale, formikValues, formikSetField }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiDeliveryPage_en: strapiDeliveryPage(locale: { eq: "en" }) {
        special_instructions_label
        over_18_text
        special_instructions_field_placeholder
        delivery_date_field_label
        delivery_time_field_label
      }
      strapiDeliveryPage_zh: strapiDeliveryPage(locale: { eq: "zh" }) {
        special_instructions_label
        over_18_text
        special_instructions_field_placeholder
        delivery_date_field_label
        delivery_time_field_label
      }
    }
  `);
  const t = deliveryTimeOptions();
  const translations = gql[`strapiDeliveryPage_${locale}`];
  const timeOptions = t.timeOptions.map((date, index) => {
    const startTime = format(subMinutes(date, 30), 'hh:mm aa');
    const endTime = format(date, 'hh:mm aa');
    return { id: index, name: `${startTime} - ${endTime}`, value: format(date, 'HH:mm') };
  });
  timeOptions.unshift({ id: -1, name: `ASAP`, value: format(t.ASAPTime, 'HH:mm') })

  return (
    <div className="flex flex-col-reverse sm:flex-col mx-4 sm:mx-0">
      <Styled.GridWrap>
        <Styled.ColWrap>
          <fieldset>
            <div className="flex items-start">
              <Notes.Block>
                <label htmlFor="comments"> {translations.special_instructions_label} </label>
                <p className="thin">{translations.over_18_text}</p>
                <Field
                  id="special_instructions"
                  as="textarea"
                  name="special_instructions"
                  rows={5}
                  placeholder={translations.special_instructions_field_placeholder}
                />
              </Notes.Block>
            </div>
          </fieldset>
        </Styled.ColWrap>
      </Styled.GridWrap>

      <Styled.GridWrap>
        <Styled.Col2Wrap>
          <fieldset>
          <Notes.Block>
            <label htmlFor="delivery_date"> {translations.delivery_date_field_label} </label>
            <DatePicker
              name="delivery_date"
              selected={formikValues.delivery_date ? new Date(formikValues.delivery_date) : null}
              onChange={(val) => {
                formikSetField('delivery_date', val);
              }}
              minDate={new Date()}
              dateFormat="dd/MM/yyyy"
            />
            <ErrorMessage name="delivery_date" component="div" className="errorMessage" />
          </Notes.Block>
          </fieldset>
        </Styled.Col2Wrap>

        <Styled.Col2Wrap>
          <fieldset>
          <Notes.Block>
            <label htmlFor="delivery_time"> {translations.delivery_time_field_label} </label>
            <Field name="delivery_time" as="select">
              {timeOptions.map((value) => (
                <>
                  <option value={value.value}>{value.name}</option>
                </>
              ))}
            </Field>
            <ErrorMessage name="delivery_time" component="div" className="errorMessage" />
          </Notes.Block>
          </fieldset>
        </Styled.Col2Wrap>
      </Styled.GridWrap>
    </div>
  );
};

DeliveryAccept.propTypes = {
  locale: PropTypes.string,
};

DeliveryAccept.defaultProps = {
  locale: 'en',
};

export default DeliveryAccept;

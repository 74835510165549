import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Styled from './../styles';
import * as Notes from './styles';
import { useI18next } from 'gatsby-plugin-react-i18next';
import DeliveryAcceptance from './deliveryAccept';
import Instruction from './instruction';
import {
  setCheckoutAcceptance,
  setCheckoutDeliveryDueDate,
  setCheckoutDeliveryInstructions,
} from '../../../state/checkout/checkout.service';
import { addHours, format, parseISO, setHours, setMinutes } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import { deliveryTimeOptions, getLocalStorageItem } from '../../../state/utils';
import { takeUntil } from 'rxjs/operators';
import { selectCart$ } from '../../../state/cart/cart.service';
import { Subject } from 'rxjs';
import Back from "../../ui/GoBackLink";

const OrderNotes = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiDeliveryPage_en: strapiDeliveryPage(locale: { eq: "en" }) {
        heading
        select_date_time_caption
        continue
        proceed_to_payment
      }
      strapiDeliveryPage_zh: strapiDeliveryPage(locale: { eq: "zh" }) {
        heading
        select_date_time_caption
        continue
        proceed_to_payment
      }
      strapiErrorMessages_en: strapiErrorMessages(locale: { eq: "en" }) {
        invalid_delivery_date
      }
      strapiErrorMessages_zh: strapiErrorMessages(locale: { eq: "zh" }) {
        invalid_delivery_date
      }
      strapiNavigationMenu_en: strapiNavigationMenu(locale: { eq: "en" }) {
        go_back
      }
      strapiNavigationMenu_zh: strapiNavigationMenu(locale: { eq: "zh" }) {
        go_back
      }
    }
  `);

  const translations = gql[`strapiDeliveryPage_${locale}`];
  const errTranslations = gql[`strapiErrorMessages_${locale}`];
  const navTranslations = gql[`strapiNavigationMenu_${locale}`];
  const { navigate } = useI18next();
  const [unMount$] = useState(new Subject());
  const [showAcceptance, setShowAcceptance] = useState(false);
  const [showInstruct, setShowInstruct] = useState(true);
  const [cacheInstructions] = useState(getLocalStorageItem('uStoreDeliveryInstructions', ''));
  const [cacheDueDate] = useState(getLocalStorageItem('uStoreDeliveryDueDate', ''));
  const [cacheAcceptance] = useState(getLocalStorageItem('uStoreDeliveryAcceptance', ''));
  const [totalUniqueItems, setTotalUniqueItems] = useState(0);

  useEffect(() => {
    selectCart$.pipe(takeUntil(unMount$)).subscribe((cart) => {
      const uniq = cart.products?.length || 0;
      setTotalUniqueItems(uniq);
    });

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  function deliveryAcceptance() {
    setShowAcceptance(true);
    setShowInstruct(false);
  }

  const validate = (values) => {
    const errors = {};
    const today = new Date();
    const selectDate = values.delivery_date ? values.delivery_date : today;
    const dateSelected = new Date(selectDate);

    today.setHours(0, 0, 0, 0);

    if (new Date(dateSelected).toISOString() < today.toISOString() || !values.delivery_date) {
      errors.delivery_date = errTranslations.invalid_delivery_date;
    } else if (!values.delivery_time) {
      errors.delivery_time = errTranslations.invalid_delivery_date;
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        special_instructions: cacheInstructions,
        delivery_date: cacheDueDate ? parseISO(cacheDueDate) : '',
        delivery_time: cacheDueDate
          ? format(parseISO(cacheDueDate), 'HH:mm')
          : format(deliveryTimeOptions().ASAPTime, 'HH:mm'),
        accepted_by: cacheAcceptance || 'Any Adult',
      }}
      validate={validate}
      onSubmit={async (values) => {
        setCheckoutDeliveryInstructions(values.special_instructions);
        setCheckoutAcceptance(values.accepted_by);

        /*
        const splitTimeArr = values.delivery_time.split(':');
        let delDate = values.delivery_date;

        if (splitTimeArr.length > 1) {
          delDate = setHours(delDate, splitTimeArr[0]);
          delDate = setMinutes(delDate, splitTimeArr[1]);
        }

        setCheckoutDeliveryDueDate(delDate);
        */

        const now = new Date();
        let delDate = values.delivery_date;
        delDate = setHours(delDate, now.getHours());
        delDate = setMinutes(delDate, now.getMinutes());
        delDate = addHours(delDate, 2);
        setCheckoutDeliveryDueDate(delDate);

        await navigate('/payment/');
      }}
    >
      {({ values, validateForm, submitForm, setFieldValue }) => (
        <Form>
          <Notes.Desktop>
            <div className="w-full bg-white pb-10">
              <Styled.HeaderWrap>{translations.heading}</Styled.HeaderWrap>
              <Instruction locale={locale} formikValues={values} formikSetField={setFieldValue} />
              <DeliveryAcceptance locale={locale} />
            </div>

            <Notes.ButtonWrap>
              {totalUniqueItems > 0 ? (
                <button type="submit" className="main">
                  {translations.continue}
                </button>
              ) : (
                <button type="button" disabled className="disabledBtn">
                  {locale === 'en' ? 'Cart is empty' : '购物车是空的'}
                </button>
              )}
            </Notes.ButtonWrap>
          </Notes.Desktop>

          <Notes.Mobile>
            <div className="header">
              <h1>{translations.heading}</h1>
              <p>{translations.select_date_time_caption}</p>
            </div>
            {showInstruct ? <ShowInstruction locale={locale} formikValues={values} formikSetField={setFieldValue} /> : null}
            {showAcceptance ? <ShowAccept locale={locale} /> : null}
            <Notes.ButtonWrap>
              {showInstruct && totalUniqueItems > 0 && (
                <button
                  type="button"
                  className="enabledBtn"
                  onClick={async () => {
                    const validatedForm = await validateForm(values);

                    let passed = true;

                    const checkFields = [
                      'delivery_date',
                      'delivery_time',
                    ];

                    for (const fieldName of checkFields) {
                      if (validatedForm[fieldName]) {
                        passed = false;
                        await submitForm();
                        break;
                      }
                    }

                    if (passed) {
                      deliveryAcceptance();
                    }
                  }}
                >
                  {translations.continue}
                </button>
              )}

              {showInstruct && totalUniqueItems < 1 && (
                <button type="button" disabled className="disabledBtn">
                  {locale === 'en' ? 'Cart is empty' : '购物车是空的'}
                </button>
              )}

              {showAcceptance && (
                <>
                  <button type="submit" className="enabledBtn">
                    {translations.proceed_to_payment}
                  </button>
                </>
              )}
            </Notes.ButtonWrap>
            <div className="py-5 px-3">
              <Back title={navTranslations.go_back} link="/checkout" />
            </div>
          </Notes.Mobile>
        </Form>
      )}
    </Formik>
  );
};

const ShowAccept = ({ locale }) => <DeliveryAcceptance locale={locale} />;

const ShowInstruction = ({ locale, formikValues, formikSetField}) => <Instruction locale={locale} formikValues={formikValues} formikSetField={formikSetField} />;

OrderNotes.propTypes = {
  locale: PropTypes.string,
};

OrderNotes.defaultProps = {
  locale: 'en',
};

export default OrderNotes;

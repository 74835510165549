import React, { useEffect, useState } from 'react';
import { concatMap, filter, map, takeUntil, tap } from 'rxjs/operators';
import PropTypes from "prop-types";
import { useI18next } from 'gatsby-plugin-react-i18next';
import Summary from '../../../components/Minicart/Summary';
import DeliveryDetails from '../../../components/Checkout/OrderNotes';

import * as Styled from './styles';
import {
  selectCheckoutAddressId$,
  selectPostcode$,
} from '../../../state/checkout/checkout.service';
import { Subject } from 'rxjs';
import { graphql, useStaticQuery } from "gatsby";
import GoBackLink from '../../ui/GoBackLink'

const Delivery = ({ locale }) => {
  const gql = useStaticQuery(graphql`
    query {
      strapiDeliveryPage_en: strapiDeliveryPage(locale: {eq: "en"}) {
        back_to_account_details
      }
      strapiDeliveryPage_zh: strapiDeliveryPage(locale: {eq: "zh"}) {
        back_to_account_details
      }
    }
  `);
  const translations = gql[`strapiDeliveryPage_${locale}`];
  const { navigate } = useI18next();
  const [unMount$] = useState(new Subject<void>());
  useEffect(() => {
    selectPostcode$
      .pipe(
        takeUntil(unMount$),
        map(async (postcode) => {
          if (!postcode?.id) {
            await navigate('/shop/');
            return null;
          }
          return postcode;
        }),
        filter((postcode) => !!postcode),
        concatMap(() => {
          return selectCheckoutAddressId$.pipe(takeUntil(unMount$), tap(async (address) => {
            if (!address) {
              await navigate('/checkout/');
            }
          }),);
        })
      )
      .subscribe();

    return () => {
      unMount$.next();
      unMount$.complete();
    };
  }, [unMount$]);

  return (
    <>
    <div className='boxed'>
      <Styled.Wrapper>
        <div className="grid grid-cols-12 gap-4 grid-wrapper ">
          <div className="col-span-4 hidden sm:block">
            <GoBackLink title={translations.back_to_account_details} link='/checkout'/>
            <div className='bg-white mt-2.5'>
              <Summary locale={locale}/>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-8 mt-7">
            <DeliveryDetails locale={locale}/>
          </div>
        </div>
      </Styled.Wrapper>
    </div>
    </>
  );
};

Delivery.propTypes = {
  locale: PropTypes.string,
};

Delivery.defaultProps = {
  locale: 'en',
};

export default Delivery;

import styled from 'styled-components';
import tw from 'twin.macro';

export const Wrapper = styled.div`
  margin-top:27px;
  @media (max-width: 990px) {
    .grid-wrapper {
      display: inherit;
      padding: 0 1.5rem;
    }
  }
`;

export const Wrap = styled.div`
  letter-spacing: 0.05em;
  ${tw`w-full sm:w-9/12 m-auto sm:flex justify-center items-center`};
`;

export const Grid = styled.div`
  ${tw`sm:flex gap-2 sm:gap-4`};
`;

export const Colspan1 = styled.div`
  width: 352px;
  ${tw``};
  @media (max-width: 768px) {
    ${tw`w-full `};
  }
`;

export const Colspan3 = styled.div`
  width: 704px;
  ${tw`sm:mt-8`};
  @media (max-width: 768px) {
    ${tw`w-full `};
  }
`;

export const HeaderWrap = styled.h4`
  line-height: 19px;
  letter-spacing: 0.04em;
  ${tw`text-base text-brown-semidark sm:px-6 py-4`};
`;

export const GridWrap = styled.div`
  ${tw`grid grid-cols-2 gap-6 sm:px-6 pt-4`};
`;

export const ColWrap = styled.div`
  margin-top:0 !important;
  ${tw`col-span-2 pt-2`};
`;

export const Col2Wrap = styled.div`
  margin-top:0 !important;
  ${tw`col-span-2 sm:col-span-1 flex flex-col pt-2`};

`;

export const CheckBoxWrap = styled.div`
  ${tw`flex items-start text-xssemi text-brown-darker sm:px-6`};
  label{
    font-family: 'Neue Haas Grotesk Text Pro N';
  }
`;

export const ButtonWrap = styled.div`
  ${tw`flex items-start text-xssemi text-brown-darker`};
  button{
    ${tw`w-full inline-flex justify-center py-2.5 px-4 text-sm font-medium bg-gray-200 text-gray-600 hover:bg-black hover:text-white`};
  }
`;
